import { colors } from '@material-ui/core';
import moment from 'moment';
import { get, handleResponse } from './network';

export const sleep = (timeMS, onTimer) =>
  new Promise((resolve, reject) => setTimeout(() => onTimer(resolve, reject), timeMS));

export const deleteCookie = name => {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
};

export const getCookie = name => {
  let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};

export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach(cookie => {
    const name = cookie.indexOf('=') > -1 ? cookie.substr(0, cookie.indexOf('=')) : cookie;
    deleteCookie(name);
  });
};

export const getUserId = (state) => {
  return parseInt(state.user.userId);
};

export const DRAWER_WIDTH = 350;

export const isLogged = () => getCookie('isLogged');

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

export const STATES = [
  { name: 'Not Started', id: 0, color: colors.grey[500] },
  { name: 'In Progress', id: 1, color: colors.yellow[700] },
  { name: 'Pending', id: 2, color: colors.lightBlue[300] },
  { name: 'Blocked', id: 3, color: colors.red[300] },
  { name: 'Ready', id: 4, color: colors.green[300] },
  { name: 'Done', id: 5, color: colors.green[700] },
  { name: 'Deferred', id: 6, color: '#000' },
];

export const fetchTaskInfo = async (clientid, projectId, iterationId, storyId, taskId) => {
  let query;
  if (storyId) {
    query = `clients/${clientid}/projects/${projectId}/iterations/${iterationId}/stories/${storyId}/tasks?id=[${taskId}]`;
  } else {
    query = `clients/${clientid}/projects/${projectId}/iterations/${iterationId}/tasks?id=[${taskId}]`;
  }
  return get(query)
    .then(handleResponse)
    .then(data => {
      return data.message[0];
    })
    .then(data => ({
      state: STATES.find(s => s.id === data.state),
      responsibles: data.responsibles,
    }));
};

export const getDuration = (start, end) =>
  Math.round((moment(end).diff(start, 'hours', true) + Number.EPSILON) * 10) / 10;

export const getUnroundedDuration = (start, end) => moment(end).diff(start, 'hours', true);

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const fromMinutesToHourMinutes = min => {
  const hours = Math.floor(min / 60);
  const minutes = Math.round(min % 60);
  const formatted =
    (hours > 0 ? hours + 'h' : '') + (hours > 0 && minutes > 0 ? ' ' : '') + (minutes > 0 ? minutes + 'm' : '');
  return formatted || '0h';
};

export const normalizeString = str =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const makeTaskPath = task => {
  return task.story.name ? `${task.project.name} > ${task.iteration.name} > ${task.story.name}` : task.iteration.name;
};
