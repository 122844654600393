import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import config from '../utils/config';
import { rankingGarbageCollectorMiddleware } from '../utils/tasks';
import ranking from './reducers/ranking';
import tasks from './reducers/tasks';
import timers from './reducers/timers';
import user from './reducers/user';
import userPreferences from './reducers/userPreferences';
import SetTransform from './transformer';

const persistConfig = {
  key: 'root',
  storage: storage,
  transforms: [SetTransform],
};

const persistConfigRanking = {
  key: 'ranking',
  storage: storage,
}

const persistConfigUser = {
  key: 'user',
  storage: storage,
}

const persistConfigUserPreferences = {
  key: 'userPreferences',
  storage: storage,
}

const reducers = combineReducers({
  timers: persistReducer(persistConfig, timers), // Persistors handle the state to persist on the refresh
  tasks,
  ranking: persistReducer(persistConfigRanking, ranking),
  user: persistReducer(persistConfigUser, user),
  userPreferences: persistReducer(persistConfigUserPreferences, userPreferences),
});

const storeEnhancer = compose(
  applyMiddleware(rankingGarbageCollectorMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const store = createStore(
  reducers,
  storeEnhancer
);

const persistor = persistStore(store);
export { persistor };
export default store;
