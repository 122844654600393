import * as actions from '../actionTypes';
import generateReducer from './generateReducer';

const initialState = {
  showStats: true,
};

const handler = {
  [actions.SHOW_STATS]: (state) => ({
    ...state,
    showStats: true,
  }),
  [actions.HIDE_STATS]: (state) => ({
    ...state,
    showStats: false,
  }),
};

export default generateReducer(handler, initialState);