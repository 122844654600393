import * as actions from './actionTypes';

function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type };

    argNames.forEach((arg, index) => {
      action[arg] = args[index];
    });

    return action;
  };
}

export const startTimer = makeActionCreator(actions.START_TIMER, 'name');
export const endTimer = makeActionCreator(actions.END_TIMER, 'id');
export const deleteTimer = makeActionCreator(actions.DELETE_TIMER, 'id');
export const linkWithTask = makeActionCreator(actions.LINK_WITH_TASK, 'linked');
export const renameTimer = makeActionCreator(actions.RENAME_TIMER, 'timer');
export const updateHeartbeat = makeActionCreator(actions.UPDATE_HEARTBEAT);
export const setTasks = makeActionCreator(actions.SET_TASKS, 'tasks');
export const addTask = makeActionCreator(actions.ADD_TASK, 'task');

export const updateRanking = makeActionCreator(actions.UPDATE_RANKING, 'id');
export const setCriteria = makeActionCreator(actions.SET_CRITERIA, 'selectedCriteria');
export const cleanRanking = makeActionCreator(actions.CLEAN_RANKING, 'cleaned');
export const pinTask = makeActionCreator(actions.PIN_TASK, 'id');
export const unpinTask = makeActionCreator(actions.UNPIN_TASK, 'id');

export const login = makeActionCreator(actions.LOGIN, 'user');
export const logout = makeActionCreator(actions.LOGOUT);

export const showStats = makeActionCreator(actions.SHOW_STATS);
export const hideStats = makeActionCreator(actions.HIDE_STATS);